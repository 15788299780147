import React from "react";
import Button from "../common/buttons/Button";
import PortableText from "react-portable-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../hooks/windowSize";

export default function Need({ data }) {
  const { width } = useWindowSize();

  return (
    <div className="relative">
      <div className="flex max-w-[1366px] mx-auto px-5">
        <div className="w-full md:w-[40%] lg:w-[50%] pt-[250px] sm:pt-[450px] md:pt-[130px] md:pb-[190px] lg:pl-[70px] show z-[100]">
          <PortableText
            content={data._rawContent}
            className={`portable-editor text-black block-content max-w-none text-base font-semibold`}
            serializers={{
              h2: ({ children }) => (
                <h2 className="text-[32px] font-primary leading-[26px]">
                  {children}
                </h2>
              ),
              h3: ({ children }) => (
                <h3 className="text-2xl font-secondary leading-[24px]">
                  {children}
                </h3>
              ),
            }}
          />

          <div className="flex flex-col sm:space-x-8  sm:flex-row items-center mt-7">
            {data?.ctaButton1?.title && (
              <div className="w-[202px]">
                <Button
                  btnText={data?.ctaButton1?.title}
                  btnLink={data?.ctaButton1?.link}
                  btnType={data?.ctaButton1?.variant}
                  linkType={data?.ctaButton1?.type}
                  formId={data?.ctaButton1?.formId}
                  fontSize="text-[13px]"
                  spacing=""
                />
              </div>
            )}
            {data?.ctaButton2?.title && (
              <div className="w-[202px]">
                <Button
                  btnText={data?.ctaButton2?.title}
                  btnLink={data?.ctaButton2?.link}
                  btnType={data?.ctaButton2?.variant}
                  linkType={data?.ctaButton2?.type}
                  formId={data?.ctaButton2?.formId}
                  borderSide="right"
                  fontSize="text-[13px]"
                  spacing=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full absolute right-0 top-0">
          <div className="relative z-50 flex w-full justify-end  lg:-mt-[80px] show">
            {width !== 0 && width > 480 ? (
              <GatsbyImage
                image={data?.desktop.asset?.gatsbyImageData}
                alt={data?.image?.alt}
                className="object-cover self-end"
              />
            ) : (
              <>
                <GatsbyImage
                  image={data?.mobile.asset?.gatsbyImageData}
                  alt={data?.image?.alt}
                  className=" object-contain w-full self-end"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
