import React, { Fragment } from "react";
import Button from "../common/buttons/Button";

export default function Authentic({ data }) {
  return (
    <div>
      <div className="max-w-[1043px] mx-auto px-5 pt-[129px] show">
        <p className=" text-[32px] font-primary leading-[46px] text-center">
          {data?.heading}
        </p>
        <div className="mt-[30px] bg-white rounded-[25px] backdrop-blur-[11px] pt-[44px] pb-[51px]">
          <div className="w-full md:w-[760px] lg:w-[797px] mx-auto">
            <div className="flex flex-col space-y-12 md:space-y-0 md:flex-row items-center justify-between md:justify-around lg:justify-between px-3">
              {data?.sliderImages?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <div className="w-[143px]">
                      <p className="font-secondary text-[32px] leading-[40px] text-center text-primary">
                        {item.heading}
                      </p>
                      <p className="text-[20px] mt-[10px] text-black leading-[30px] text-center font-poppins">
                        {item.subHeading}
                      </p>
                    </div>
                    {index < data?.sliderImages?.length - 1 && (
                      <div className="bg-black w-[1px] h-[126px] hidden md:block" />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col  md:flex-row md:space-x-16 items-center justify-center mt-[58px]">
          {data?.ctaButton1?.title && (
            <div className="w-[264px]">
              <Button
                btnText={data?.ctaButton1?.title}
                btnLink={data?.ctaButton1?.link}
                btnType={data?.ctaButton1?.variant}
                linkType={data?.ctaButton1?.type}
                formId={data?.ctaButton1?.formId}
                fontSize="text-base"
                spacing=""
              />
            </div>
          )}
          {data?.ctaButton2?.title && (
            <div className="w-[264px]">
              <Button
                btnText={data?.ctaButton2?.title}
                btnLink={data?.ctaButton2?.link}
                btnType={data?.ctaButton2?.variant}
                linkType={data?.ctaButton2?.type}
                formId={data?.ctaButton2?.formId}
                borderSide="right"
                fontSize="text-base"
                spacing=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
