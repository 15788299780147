import React, { useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../buttons/Button";
import { MdClose } from "react-icons/md";

export default function WelcomePopup({ onClose, value, data }) {
  useEffect(() => {
    // document.body.style.overflow = "hidden";
    // return () => {
    //   document.body.style.overflow = "unset";
    // };
    if (value) {
      document.getElementById("popup").style.visibility = "visible";
      document.getElementById("popup").style.opacity = "1";
    } else {
      document.getElementById("popup").style.opacity = "0";
      setTimeout(() => {
        document.getElementById("popup").style.visibility = "hidden";
      }, 500);
    }
  }, [value]);
  return (
    <div
      className={`fixed top-0 transition-opacity duration-700 ${
        value ? "visible" : "hidden"
      } bottom-0 left-0 right-0 bg-black/30 z-[150] backdrop-blur-[4.5px]`}
      id="popup"
    >
      <div className="absolute blur-xl top-0 left-0 right-0 bottom-0" />
      <div className="flex h-full w-full justify-center items-center z[100]">
        <div
          className="w-[949px] bg-white rounded-tl rounded-tr rounded-bl-[31px] rounded-br-[31px]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(41,217,211,0.6), rgba(69,204,55,0.6)`,
          }}
        >
          <span
            className="absolute z-50 right-3 top-2 p-1 cursor-pointer"
            onClick={onClose}
          >
            <MdClose size={35} color="#fff" />
          </span>
          <div>
            <GatsbyImage
              image={data?.image?.asset?.gatsbyImageData}
              placeholder="blurred"
              alt="welcome"
              className="rounded-tl rounded-tr"
            />
          </div>
          <div className="py-[29px] px-[44px] relative">
            <div className="relative">
              <p className="font-primary text-black leading-[29px] text-base sm:text-[20px] font-semibold mb-3">
                {data?.heading}
              </p>
              <p className="text-xs sm:text-base leading-6 font-poppins">
                {data?.description}
              </p>
              {data?.ctaButton?.title && (
                <div className="w-[258px] mt-6">
                  <Button
                    btnText={data?.ctaButton?.title}
                    btnLink={data?.ctaButton?.link}
                    btnType={data?.ctaButton?.variant}
                    linkType={data?.ctaButton?.type}
                    formId={data?.ctaButton?.formId}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
