import React from "react";
import Slider from "react-slick";
// import { GatsbyImage } from "gatsby-plugin-image";
import Image from "gatsby-plugin-sanity-image";
import "./style.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: "slick-dots slick-thumb",
  responsive: [
    {
      breakpoint: 769,
      settings: {
        centerPadding: "50px",
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        centerPadding: "50px",
        slidesToShow: 1,
      },
    },
  ],
};

export default function YourGuides({ data }) {
  return (
    <div className="mt-[121px] max-w-[1366px] px-5 lg:px-0 mx-auto show">
      <div className="flex justify-center flex-col items-center">
        <p className="text-[32px] leading-[46px] text-center font-primary font-normal ">
          {data?.title}
        </p>
        <p className="text-[20px] max-w-[1001px] px-3 sm:px-[30px] leading-[30px] text-center font-poppins italic font-normal mt-[21px]">
          {data?.firstQuote}
          <br />
          {data?.quote1}
        </p>

        <div className="tb-containerv  mt-[100px]">
          <div className="hg-slider mx-auto">
            <Slider {...settings}>
              {data?.sliderImage?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full sm:max-w-[282px] mb-[61px] rounded-[44px] min-h-[380px] flex justify-center items-center border border-black bg-white/[.38]"
                  >
                    <div className="mx-auto img_rounded overflow-hidden rounded-full h-[180px] w-[180px] mb-2 flex justify-center mt-[21px]">
                      {/* <GatsbyImage
                        image={item?.photo?.asset?.gatsbyImageData}
                        alt={item?.photo?.alt}
                      /> */}
                      {item?.photo?.asset && (
                        <Image
                          asset={item?.photo?.asset}
                          hotspot={item.photo.hotspot}
                          crop={item?.photo.crop}
                          alt={item?.photo?.alt}
                          loading="eager"
                          width={180}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                    <div className="flex justify-center flex-col text-center text-[#323a44] mb-[2.5rem]">
                      <p className="text-base leading-[23px] text-center font-semibold  font-primary mb-[23px]">
                        {item.name}
                      </p>
                      <p className="text-base max-w-[200px] text-center leading-[24px] font-poppins mx-auto">
                        {item.guidance} {item.leader}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
