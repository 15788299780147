import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Hero from "../components/home/Hero";
import Seo from "../components/seo";
import Layout from "../components/layouts";
import Need from "../components/home/Need";
import Authentic from "../components/home/Authentic";
import Panama from "../components/home/Panama";
import Schedule from "../components/home/Schedule";
import YourGuide from "../components/home/yourGuides";
import TranqiuloBay from "../components/home/TranqiuloBay";
import Companies from "../components/home/Companies";
import HomeFooter from "../components/layouts/HomeFooter";
import WelcomePopup from "../components/common/popup";

const IndexPage = ({ data: { sanityHomePage, sanitySitesettings } }) => {
  const [popup, setPopup] = useState(false);

  let seo;
  let hero;
  let features;
  let bookVacation;
  let scheduleVacation;
  let tranqiulo;
  let guide;
  let company;
  let popupData;
  let footer1;

  sanityHomePage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "bookVacation") {
      bookVacation = element;
    } else if (element._type === "scheduleVacation") {
      scheduleVacation = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "simple") {
      tranqiulo = element;
    } else if (element._type === "guidesSection") {
      company = element;
    } else if (element._type === "popup") {
      popupData = element;
    }
  });
  // useEffect(() => {
  //   if (popupData?.state) {
  //     const popupSession = sessionStorage.getItem("@popup");
  //     if (!popupSession) {
  //       sessionStorage.setItem("@popup", "done");
  //       setTimeout(() => {
  //         setPopup(true);
  //       }, popupData?.delay);
  //     }
  //   }
  // }, []);
  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight - 350;
    if (bottom) {
      const popupSession = sessionStorage.getItem("@popup");
      if (!popupSession) {
        sessionStorage.setItem("@popup", "done");
        setPopup(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  sanitySitesettings?.pageBuilder?.forEach((element) => {
    if (element._type === "siteFooter") {
      footer1 = element;
    }
  });
  return (
    <Layout footer={false}>
      <Seo data={seo} />
      <Hero data={hero} logo={sanitySitesettings?.logo} features={features} />
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(69,204,55,0.6), rgba(41,217,211,0.6),rgba(69,204,55,0.6),rgba(41,217,211,0.6))`,
        }}
      >
        {sanityHomePage?.contentWithImage && (
          <Need data={sanityHomePage?.contentWithImage} />
        )}
        {bookVacation && <Authentic data={bookVacation} />}
        {sanityHomePage?.contentWithImage2 && (
          <Panama data={sanityHomePage?.contentWithImage2} />
        )}
        {scheduleVacation && <Schedule data={scheduleVacation} />}
        {company && <YourGuide data={company} />}
        {tranqiulo && <TranqiuloBay data={tranqiulo} />}
        {guide && <Companies data={guide} />}
        <div className="mt-[100px] md:mt-[158px]">
          <HomeFooter
            footer={footer1}
            link={sanityHomePage?.homeSocialLink}
            logo={sanitySitesettings?.logo}
          />
        </div>
      </div>
      {popupData && (
        <WelcomePopup
          onClose={() => setPopup(false)}
          value={popup}
          data={popupData}
        />
      )}
    </Layout>
  );
};

export default IndexPage;
export const query = graphql`
  query {
    sanityHomePage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }

        ... on SanityBookVacation {
          _type
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
          }
          sliderImages {
            heading
            subHeading
          }
        }

        ... on SanityScheduleVacation {
          _type
          desLink
          description
          heading
          ctaButton1 {
            link
            title
            type
            variant
            formId
          }
          scheduleItem {
            heading
            subHeading
            itemImg {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  height: 290
                  width: 400
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityGuidesSection {
          _type
          quote1
          sliderImage {
            photo {
              asset {
                _id
                metadata {
                  blurHash
                }
              }
              alt
              crop {
                bottom
                left
                right
                top
              }
              hotspot {
                x
                y
              }
            }
            guidance
            leader
            name
          }
          title
          firstQuote
        }

        ... on SanityCompanyGuided {
          _type
          heading
          list {
            width
            logo {
              alt
              asset {
                gatsbyImageData(
                  fit: FILLMAX
                  placeholder: BLURRED
                  formats: WEBP
                )
              }
            }
          }
        }
        ... on SanityPopup {
          _type
          description
          heading
          state
          image {
            asset {
              gatsbyImageData(
                fit: FILLMAX
                placeholder: BLURRED
                formats: WEBP
                height: 350
                width: 1000
              )
            }
          }
          ctaButton {
            formId
            link
            title
            type
            variant
            formId
          }
        }
        ... on SanitySimple {
          _type
          heading
          _rawContent
          button
          sliderItems {
            title
            content
            ctaButton1 {
              link
              title
              type
              variant
              formId
            }
            slideBg {
              asset {
                url
              }
            }
          }
        }
      }
      contentWithImage {
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        _rawContent
        desktop: image {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              formats: WEBP
              placeholder: BLURRED
              height: 746
            )
          }
          alt
        }
        mobile: image {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              formats: WEBP
              placeholder: BLURRED
              height: 246
            )
          }
          alt
        }
      }
      contentWithImage2 {
        ctaButton1 {
          link
          title
          type
          variant
          formId
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
        }
        _rawContent
        image {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              formats: WEBP
              placeholder: BLURRED
              height: 800
            )
          }
          alt
        }
      }

      homeSocialLink {
        brandName
        logoUrl
        svgs
      }
    }
    sanitySitesettings {
      pageBuilder {
        ... on SanitySiteFooter {
          _type
          cc
          email
          phone1
          tel1
          phone2
          tel2
        }
      }
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, formats: WEBP, placeholder: BLURRED)
        }
      }
    }
  }
`;
