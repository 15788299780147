import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import Button from "../common/buttons/Button";
import Logo from "../common/logo";

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  className: "hero-slider",
};

const Hero = ({
  data,
  logo,
  h1Size = "text-2xl md:text-[50px]",
  h2Size = "text-xl sm:text-2xl",
  h1P = "",
  h2P = "pb-5",
  features = null,
}) => {
  return (
    <div className="relative max-w-[100vw] block align-baseline box-border z-[1] h-[700px] overflow-hidden">
      <div className="relative block mx-auto overflow-visible box-border">
        {/* Images */}
        <div className="relative w-screen h-full box-border">
          <div className="list-none relative block h-full">
            <Slider {...settings}>
              {data?.bgImages.map((item, index) => {
                return (
                  <div
                    className="absolute block top-0 z-[2] before:bg-[#26262657] before:contents-[''] before:inset-0
              before:h-full before:absolute before:z-[2] before:transition-opacity duration-[80ms] ease-linear"
                    key={index}
                  >
                    <GatsbyImage
                      image={item?.asset?.gatsbyImageData}
                      alt={item?.alt}
                      loading="lazy"
                      className="backface-hidden relative top-0 left-0 w-screen h-[700px] object-cover object-center"
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="absolute block overflow-visible z-[2] top-[151px]  pointer-events-auto w-full">
        <div className="relative block">
          <div className="relative block max-w-[900px] mx-auto">
            <div className="flex flex-col flex-wrap justify-end px-[10px] pb-10 text-white">
              <h1
                className={`${h1Size} ${h1P} font-bold word-spacing text-center leading-8 md:leading-[72px] font-primary mb-[10px]`}
              >
                {data?.heading1}
              </h1>

              <h1
                className={`${h2Size} ${h2P} tracking-[2px] font-normal text-center leading-6 font-secondary sm:leading-[30px]`}
              >
                {data?.heading2}
              </h1>

              <div className="relative block text-center mt-[30px]">
                <Button
                  btnText="REQUEST RESERVATION"
                  btnLink={data?.ctaButton?.link}
                  small
                  btnType={data?.ctaButton?.variant}
                  linkType={data?.ctaButton?.type}
                  formId={data?.ctaButton?.formId}
                  height="h-[50px] sm:h-[68px]"
                />
              </div>
              {logo && (
                <div className="mt-[90px]">
                  <Logo logo={logo} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {features && (
        <div className="absolute bottom-5 left-0 right-0 z-[100]">
          <div className="w-ful px-2 md:px-0 md:w-[760px] lg:w-[797px] mx-auto">
            <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row items-center justify-between px-3">
              {features?.featureItems?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <div
                      className="flex items-center justify-center"
                      key={index}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: item.icon }}
                        className="flex justify-center"
                      />
                      <p className="text-[20px] ml-3 text-white leading-[28px] text-center font-primary">
                        {item.title}
                      </p>
                    </div>{" "}
                    {index < features?.featureItems?.length - 1 && (
                      <div className="bg-white w-[1px] h-[24px] hidden md:block"></div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
