import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Companies({ data }) {
  return (
    <div className="max-w-[872px] mx-auto px-5 mt-[50px] show">
      <p className=" text-[32px] font-primary leading-[46px] text-center">
        {data?.title}
      </p>
      <div className="mt-[78px]">
        <div className="flex flex-wrap space-y-5  space-x-2 justify-around sm:justify-between items-center">
          {data?.list?.map((item, index) => {
            return (
              <div
                key={index}
                className="w-full"
                style={{ position: "relative", width: item.width }}
              >
                <GatsbyImage
                  image={item?.logo?.asset?.gatsbyImageData}
                  alt={item?.logo.alt}
                  objectFit="contain"
                  className="transition-transform duration-500 hover:scale-110"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
