import React from "react";
import { Link } from "gatsby";
import Button from "../common/buttons/Button";
import { GatsbyImage } from "gatsby-plugin-image";
import { PopupButton } from "@typeform/embed-react";

export default function Schedule({ data }) {
  return (
    <div className="relative show">
      {/* Schedule */}
      <div className="max-w-[860px] mx-auto px-5 lg:px-0">
        <p className="text-[27px] font-primary text-center text-black leading-[39px] mb-[68px]">
          {data?.heading}
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-[56px]  md:grid-cols-3">
          {data?.scheduleItem?.map((item, index) => {
            return (
              <div
                className="rounded-[7px] border-[0.3px] border-black transition-all duration-300 hover:shadow-2xl hover:scale-105"
                key={index}
              >
                <PopupButton id="pCocsLbQ">
                  <div className="relative schedule_rounded  -m-[0.5px] cursor-pointer">
                    <GatsbyImage
                      image={item?.itemImg?.asset.gatsbyImageData}
                      alt={item?.itemImg?.alt}
                      className="rounded-[7px] w-full"
                      loading="lazy"
                    />
                  </div>

                  <div className="mt-[26px] mb-2 flex justify-center flex-col items-center px-[11px] pb-[21px]">
                    <p className="text-[27px] leading-[33px] font-normal font-secondary text-center w-[162px]">
                      {item.heading}
                    </p>
                    <p className="text-[17px] leading-[25px] text-center font-poppins">
                      {" "}
                      {item.subHeading}
                    </p>
                  </div>
                </PopupButton>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-[47px] max-w-[870px] px-5 sm:px-0 text-center mx-auto">
        <div className="w-full pb-5">
          <Link
            to={data?.desLink}
            className="text-[17px] leading-[25px] font-poppins"
          >
            {data?.description}
          </Link>
        </div>
        <div className="w-[250px] mx-auto">
          <Button
            btnText={data?.ctaButton1?.title}
            btnLink={data?.ctaButton1?.link}
            btnType={data?.ctaButton1?.variant}
            linkType={data?.ctaButton1?.type}
            formId={data?.ctaButton1?.formId}
            m="mt-[27px]"
          />
        </div>
      </div>
    </div>
  );
}
